/** ***************************************************************************
 * Templates / CMS page
 *************************************************************************** */

:root {
  --hns-c-page--Grid:
    "head"
    "main" 1fr
    "foot";
}


/**
 * Component
 */

body {
  --template-columns: var(--columns-default);
}



/**
 * Header
 */

body > header {
  display: grid;
  grid-area: head;
  grid-template-columns: var(--columns-default);
}



/**
 * Footer
 */

body > footer {
  grid-area: foot;
}



/**
 * Main content
 */

main {
  /* overflow: hidden; */  /* CTA rocks. breaks sticky stuff */
  display: grid;
  grid-template-columns: var(--columns-default);
  transition: padding-top var(--hns-global--Duration--sm) linear;
}



/**
 * Old code
 */

  /* layout */

  /* logo */
    body > header > a > svg {
      justify-self: start;
      align-self: stretch;
      height: 100%;
    }

    body > header .background svg {
      --color-foreground-a: .7;
    }

    main > * {
      grid-column: var(--column-content-start) / var(--column-content-end);
    }

    main > .hns-c-card-holder {
      grid-column: content-start / content-end;
    }

    #hns-c-crag-filters,
    main > .charlie-holder,
    main > .monstruo,
    main > .hns-c-sectioning,
    main > .hns-c-cta,
    main > .latest-news,
    main > .hns-c-gallery,
    main > .hns-c-video-player,
    main > img {
      grid-column: viewport-start / viewport-end;
    }

    main > img {
      max-height: 33rem; /* 528px */
      object-fit: cover;
      object-position: center;
      width: 100%;
    }

    @media (min-width: 34rem) { /* sm */
      main > .hns-c-card-holder {
        grid-column: 4 / -6
      }
    }

    @media (min-width: 64rem) { /* lg */
      main > .hns-c-cta.align-left,
      main > .hns-c-cta.align-right {
        grid-column: content-start / -5;
      }
    }

    /* vertical spacings */

      main .hns-c-sectioning {
        --hns-c-sectioning--PaddingBlockStart: 5rem;
      }

      main > .hns-c-sectioning {
        padding-bottom: 3rem;
      }

      main > * {
        margin: 0;
        transition: margin-top var(--hns-global--Duration--sm) linear;
      }

      main > img + p,
      main > p + p {
        margin-top: 1em;
      }

      main > img + p {
        margin-top: 4em;
      }

      main > .hns-c-cta {
        /* margin-top: 2rem; */ /* 32px */
        margin-top: 1rem; /* 16px */
      }

      main > p + .hns-c-cta {
        margin-top: 4rem;
      }

      main > .hns-c-cta + .latest-news {
        margin-top: 5.5rem; /* 88px */
      }

      main > * + .charlie-holder {
        margin-top: 5.5rem; /* 88px, this comes from xl */
      }

      main > .charlie-holder + .hns-c-cta {
        margin-top: 5.5rem; /* 88px, this comes from xl */
      }

      main > .djangocms-googlemap {
        margin-top: 3.5rem; /* 56px, this comes from xl */
      }

      main > .djangocms-googlemap + .hns-c-cta {
        margin-top: 3.5rem; /* 56px, this comes from xl */
      }

      main > section:last-child > .hns-c-card-holder,
      main > .post-list:last-child {
        margin-bottom: 7rem;
      }

      main > .hns-c-cta:last-child {
        margin-bottom: 1rem;
      }

      @media (min-width: 48rem) { /* md */
        main > .hns-c-sectioning {
          padding-bottom: 6.25rem;
        }
      }

      @media (min-width: 64rem) { /* lg */
        main .hns-c-sectioning {
          --hns-c-sectioning--PaddingBlockStart: calc(4.54rem + 6.25rem); /* make room for rocks and add 100px */
        }

        main > .hns-c-cta {
          margin-top: 2.5rem; /* 40px */
        }

        main > p + .hns-c-cta {
          margin-top: 5rem;
        }

        main > .hns-c-cta + .latest-news {
          margin-top: 6.75rem; /* 109px */
        }

        main > .hns-c-cta:last-child {
          margin-bottom: 6rem;
        }
      }

      @media (min-width: 90rem) { /* hg */
        main > .hns-c-cta + .latest-news {
          margin-top: 10.75rem; /* 173px */
        }
      }

  /* post list */

    main {
      --column-post-list-end: viewport-start;
      --column-post-list-start: viewport-end;
    }

    main  > .post-list {
      grid-column: var(--column-post-list-start) / var(--column-post-list-end);
    }

    /*
     * sm
     */
    @media (min-width: 34rem) {
      main {
        --column-post-list-end:-5;
        --column-post-list-start: 5;
      }
    }

    /*
     * lg
     */
    @media (min-width: 64rem) {
      main {
        --column-post-list-end: -6;
        --column-post-list-start: 6;
      }
    }

    /*
     * hg
     */
    @media (min-width: 90rem) {
      main {
        --column-post-list-start: 4;
      }
    }
